.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 16px;
}
  
.titleContainer{
  text-align: center;
}

  .userSection {
    margin-bottom: 16px;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .userHeader {
    padding: 0px 16px;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    background-color: white;
  }
  
  .table th, .table td {
    /* padding: 8px; */
    border: 1px solid #e5e7eb;
    /* width: 10%; */
  }
  
  .table th {
    background-color: rgba(0, 0, 0, 0.1);
    font-weight: bold;
  }
  
  .textCenter {
    text-align: center;
  }
  
  .goal {
    /* padding: 4px 8px; */
    border-radius: 4px;
    display: inline-block;
    min-width: 24px;
  }
  
  .tabContainer {
    display: flex;
    margin-top: 16px;
    border-bottom: 1px solid #ccc;
    position: relative;
    padding-left: 8px;
  }
  
  .tab {
    padding: 8px 16px;
    border: 1px solid #ccc;
    border-bottom: none;
    background-color: #f1f3f4;
    cursor: pointer;
    margin-right: 2px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    font-size: 14px;
    position: relative;
    bottom: -1px;
  }
  
  .activeTab {
    background-color: white;
    border-bottom: 1px solid white;
    z-index: 2;
  }
  
  .tab:hover:not(.activeTab) {
    background-color: #e8eaed;
  }
  
  /* Add a subtle shadow to the active tab */
  .activeTab::before {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    height: 3px;
    background-color: #4285f4; /* Google's blue color */
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  
.indigo { background-color: #4338ca; }
.red { background-color: #b91c1c; }
.green { background-color: #15803d; }
.yellow { background-color: #ca8a04; }
.purple { background-color: #7e22ce; }
.orange { background-color: #ea580c; }
.pink { background-color: #be185d; }

.indigoGoal { background-color: #e0e7ff; }
.redGoal { background-color: #fee2e2; }
.greenGoal { background-color: #dcfce7; }
.yellowGoal { background-color: #fef9c3; }
.purpleGoal { background-color: #f3e8ff; }
.orangeGoal { background-color: #ffedd5; }
.pinkGoal { background-color: #fce7f3; }

.successGreen {
  color: green;

}

.successYellow {
  color: #ffa500; 

}

.successRed {
  color: red;

}
.weekSelect {
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
}

.weekSelect:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}
