.dashboard {
    padding: 20px;
    font-family: Arial, sans-serif;
}

.titleContainer{
  text-align: center;
}

.addTaskContainer{
  display: flex;
}
.idBox{
  flex:1 1 0;
}
.addTaskForm{
  flex : 1 1 0;
}

.addWeekButton {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 20px;
}
  
.addWeekButton:hover {
  background-color: #45a049;
}

.dashboardTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.dashboardTable th, .dashboardTable td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}

.dashboardTable th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.dashboardTable tr:nth-child(even) {
  background-color: #f9f9f9;
}

.dashboardTable tr:hover {
  background-color: #f5f5f5;
}

.addUserForm input{
  flex: 1 0 auto;
}

.addUserForm, .addTaskForm {
  margin-bottom: 20px;
  display: flex;
  gap: 10px;

  flex-wrap:wrap;

}

.addUserForm input, .addTaskForm input, .addTaskForm select {
  padding: 5px;
  font-size: 14px;
}

.addUserForm button, .addTaskForm button {
  padding: 5px 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
}

.addUserForm button:hover, .addTaskForm button:hover {
  background-color: #45a049;
}

.goalBox{
  display: flex;
}
.goalBox span:first-child{
  flex: 5 1 0;
  /* background-color: red; */
}
.deleteBtn {
  flex: 1 1 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14px;  /* 원하는 전체 너비 */
  height: 14px; 
  padding: 4px;
  border-radius: 3px;
  border: 2px solid #ddd;
  /* box-shadow: 0px 0px 6px gray; */
  cursor: pointer; /* 마우스 오버 시 포인터 표시 */
}

.deleteBtn::before {
  content: "x";
  font-size: 16px; /* 원하는 텍스트 크기 */
  line-height: 1;
}
