.checkbox {
    width: 15px;
    height: 15px;
    
    border: 2px solid #ccc;
    border-radius: 4px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s ease;
}
.checkbox.disabled {
  opacity: 0.35;
  background-color: gray;
  cursor: not-allowed;
  /* pointer-events:none; */
}
.checked {
  background-color: #3b82f6;
  border-color: #3b82f6;
  cursor: pointer;
}

.checkmark {
  color: white;
}

.checkboxContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
}

.uploadContainer {
  position: fixed;
  width: 80%;
  height: 80%;
  top: 10%;
  left: 10%;
  background: white;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 16px;
  z-index: 10;
  box-shadow:  0px 0px 100px black;

}
.uploadContainer img{
  width: 100%;
  margin-top: 10%;
  height: 75%;
  object-fit: contain;
}


.fileInput {
  margin-bottom: 10px;
  /* position: inherit; */
  top:50%;
  left:30%;
  width: 40%;
}

.submitButton {
  position:fixed;
  width: 20%;
  left:40%;
  right: 40%;
  bottom:15%;
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}

.exitButton{
  /* background-color: #3b82f6; */
  position: fixed;
  top:15%;
  right:13%;
}
.status{
  font-weight: bold;
  font-size : 1.4rem;
}