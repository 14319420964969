/* Register.module.css */
.register {
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
    padding: var(--spacing-large);
}
  
.register form div {
  margin-bottom: var(--spacing-medium);
}
.register form p {
  margin-top: -10px;
  font-size: var(--font-size-small);
}

.register label {
  display: block;
  margin-bottom: var(--spacing-small);
}

.register input {
  display: block;
  /* width: 30%; */
  padding: var(--spacing-small);
  margin: var(--spacing-small) auto;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
}
  
  .btn {
    background-color: var(--primary-color);
    color: var(--background-color);
    padding: var(--spacing-small) var(--spacing-medium);
    border: none;
    border-radius: var(--border-radius);
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
  }
  
  .btn:hover {
    background-color: var(--secondary-color);
  }